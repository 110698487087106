import React from "react";
import "../styles/AboutWelcome.css";
import {HashLink as Link} from 'react-router-hash-link';
// import Slider from "../components/ImageCarousel";
// import SimpleImageSlider from "react-simple-image-slider/dist/ImageSlider";
import Slider2 from "../components/Silder2";
import Navbar2 from "../components/Navbar2";

function AboutWelcome() {
  return (
    <div className="aboutWelcome" id="aboutWelcome">
      <div className="aboutWelcomeNav">
        <a href="/"><div className="logo">Name/Logo Here</div></a>
        <a href="/about"><h3>ABOUT</h3></a>
        {/* <Link to="/about"><h3>About</h3></Link> */}
        <Link><h3></h3></Link>
        <Link><h3>GALLERY</h3></Link>
        <Link><h3>MENU</h3></Link>
        <a href="/contact"><h3>CONTACT</h3></a>
      </div>
      {/* <div className="aboutWelcomeNav2"> */}
        {/* <Navbar2/> */}
      {/* </div> */}

    {/* <div className="aboutWelcomeLeft"> */}
    {/* <Slider></Slider> */}
    {/* </div> */}
    {/* <div className="aboutWelcomeRight"> */}
    {/* <h1>Welcome</h1> */}
    {/* <p> About and History "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
          {/* <h1> ? </h1>
          <p>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</p> */}
          {/* <Slider2 /> */}
    </div>
  // </div> */}
    );
  }

export default AboutWelcome;
