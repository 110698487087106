import React from "react";
import Slider2 from "../components/Silder2";
import "../styles/About.css";
import pic1 from "../assets/squireCapture3.JPG";
import pic2 from "../assets/SquireCapture4.JPG";
import { BrowserRouter as Router, Link, useNavigate, Route, Switch } from "react-router-dom";
// import Slider from "../components/ImageCarousel";


function About() {
  return (
    <div 
    className="about" id="about">
      {/* <div className="aboutTop">
        
      </div> */}
        <div className="aboutRight">

        <div className="aboutLeft">
          {/* <Slider2></Slider2> */}
          {/* <slider></slider> */}
        </div>

          <h1> Tag Line adipiscing elit, exercitation ullamco laboris nisi ut aliquip ex ea </h1>
          <p>About and History "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          
          <h1> Hours </h1>
          <p>Monday - Thursday</p>
          <p>3pm - 10pm</p>
          <p>Friday - Saturday</p>
          <p>3pm - 2am</p>
          <p>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod.</p>

        </div>
    </div>
  );
}

export default About;
