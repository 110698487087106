import React, { useState, useEffect } from 'react';
import "./App.css";
import moon from './assets/squiremain.JPG';
import land from './assets/SquireCapture4.JPG';
import cat from './assets/squireCapture3.JPG';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import Footer from "./components/Footer";
import Bottom from "./pages/Bottom";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navbar2 from "./components/Navbar2";
import AboutWelcome from "./pages/AboutWelcome";
import Parallax2 from './components/2Parallax';

function App() {

    const [x,setx] = useState(false);
    
      // useEffect((varx) => {
        // if(x === true) {
          // console.log(window.location.href);
          // if (window.screen.width < 950 && window.location.href === "http://localhost:3000/") {
            
          //   // if (window.screen.width < 950) {
          //     // console.log(x);
          //     // setx(false);
          //     console.log(x);
          //     window.location = "./about";
          //     // console.log('working');
          //     // RedirectExample();
          //     //  }
          //     // return setx(false)
          //   }

  return (
    <div className="App">
      <Router>

      {/* <Routes> */}
        <Route exact path="/">
       
        <Parallax pages={1.7} 
          style={{backgroundColor: 'black'}}
          >
        <ParallaxLayer
              // className="Cat"
              speed={1}
              factor={1}
              offset={0}
              style={{
                width: '100%',
                hieght: '100%',
              //   zIndex: '-5',
                backgroundSize: 'cover',
                backgroundImage: `url('https://images.unsplash.com/photo-1485770958101-9dd7e4ea6d93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80')`,
              }}
              >
              <div>
              <Navbar2 />
              <AboutWelcome />

              </div>

            </ParallaxLayer>
              {/* <Home /> */}
        {/* <Parallax2 /> */}
        
        {/* <Menu /> */}

        <ParallaxLayer
                    speed={1}
                    factor={.8}
                    offset={.8}
        >
        <div>

        <Contact />
        </div>
        </ParallaxLayer>

        </Parallax>
      </Route>

{/* ///////////////////////////about//////////////////////// */}
    
      <Route exact path="/about">
          <Parallax2></Parallax2>
          <About></About>
      </Route>

      {/* </Routes> */}
      </Router>
    </div>
  );
}

export default App;
